import * as React from "react"
import shortid from  "shortid";

const BrainingCampSection1 = (props) => {
    return (
        <section className="section-1">
            <div className="container">
                <div className="image-block-1">
                    <div className="row">
                        <div className="col-sm-24">
                            <div className="image-block">
                                <img
                                    src={props.image11x?.sourceUrl}
                                    srcSet={props.image12x?.sourceUrl + " 2x, " + props.image11x?.sourceUrl + " 1x"}
                                    width={props.image11x?.width}
                                    alt={props.image11x?.altText}
                                />
                            </div>
                        </div>
                        <div className="col-sm-12">
                            <div className="image-block" >
                                <img
                                    src={props.image21x?.sourceUrl}
                                    srcSet={props.image22x?.sourceUrl + " 2x, " + props.image21x?.sourceUrl + " 1x"}
                                    width={props.image21x?.width}
                                    alt={props.image21x?.altText}
                                />
                            </div>
                        </div>
                        <div className="col-sm-12">
                            <div className="image-block" >
                                <img
                                    src={props.image31x?.sourceUrl}
                                    srcSet={props.image32x?.sourceUrl + " 2x, " + props.image31x?.sourceUrl + " 1x"}
                                    width={props.image31x?.width}
                                    alt={props.image31x?.altText}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="work-we-do">
                    <div className="row">
                        <div className="col-lg-2"></div>
                        <div className="col-lg-20">
                            <h1 className="h1 section-title normal-spacing">{props.mainTitle}</h1>
                            <p className="section-description">{props.mainDescription}</p>
                        </div>
                        <div className="col-lg-2"></div>
                    </div>
                </div>
                <div className="image-block-2">
                    <div className="image-items">
                        {props.imageslist?.map((object, i) => {
                            return (
                                <div className="item" key={shortid.generate()}>
                                    <img
                                        src={object.image1x?.sourceUrl}
                                        srcSet={object.image2x?.sourceUrl + " 2x, " + object.image1x?.sourceUrl + " 1x"}
                                        width={object.image1x?.width}
                                        alt={object.image1x?.altText}
                                    />
                                </div>     
                            )
                        })}
                    </div>
                </div> */}
            </div>
        </section> 
    )
}

export default BrainingCampSection1;